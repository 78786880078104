import React from "react";
import Modal from "@mui/material/Modal";
import "./styles/ModalNotice.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  height: "200px",
  borderRadius: "10px",
  backgroundColor: "white"
};

function CommentModal({
  isCommentModalOpen,
  modalMessage,
  onClose
}) {
  return (  
        <Modal
            open={isCommentModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <div style={ModalStyle}>
            <div className="userDetailsCard_header" style={{ display:"flex", justifyContent:"flex-end" }}>
              <HighlightOffIcon
                sx={{
                  mr: "5px",
                  "&:hover": {
                    color: "gray",
                    transition: "color 0.3s"
                  }
                }}
                onClick={onClose}
              ></HighlightOffIcon>
            </div>
            <div className='commentAlert'>
              {modalMessage}
            </div>
          </div>
        </Modal>
  );
}
  
export default CommentModal;

