import React, { useEffect,useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function FilterInAffiliate({ setSearchText, handelToggleAddNewModal }) {
  const [searchLocalText, setSearchLocalText] = useState("");
  const [csvToDownload, setCsvToDownload] = useState("");
  const { data } = useSelector((state) => ({
    data: state?.AdminAffiliateReducer?.data
  }));

useEffect(() => {
    let tempAffliates = [];
    data.forEach((element) => {
      let obj = {
        "Affliliate abbreviation": element.abbreviation,
        "Affiliate Name": element.name,
        "createdAt": element.createdAt,
        "created_by": element.created_by,
        "description": element.description,
        "hubAudience": element.hubAudience,
        "isActive": element.isActive,
        "updated_by": element.updated_by,
        "updatedAt": element.updatedAt,
        
        "elevatedRoleApproverGroup": Array.isArray(element.elevatedRoleApproverGroup) ? 
        element.elevatedRoleApproverGroup.map((object) => {
        return object.adGroupName ? object.adGroupName : '';
        }) : 
        (typeof element.elevatedRoleApproverGroup === 'object' && element.elevatedRoleApproverGroup !== null) ? 
        (element.elevatedRoleApproverGroup.adGroupName ? element.elevatedRoleApproverGroup.adGroupName : '') : 
        [],

        "trainingApproverGroup": Array.isArray(element.trainingApproverGroup) ? 
        element.trainingApproverGroup.map((object) => {
        return object.adGroupName ? object.adGroupName : '';
        }) : 
        (typeof element.trainingApproverGroup === 'object' && element.trainingApproverGroup !== null) ? 
        (element.trainingApproverGroup.adGroupName ? element.trainingApproverGroup.adGroupName : '') : 
        [],

        "sponsorApproverGroup": Array.isArray(element.sponsorApproverGroup) ? 
        element.sponsorApproverGroup.map((object) => {
        return object.adGroupName ? object.adGroupName : '';
        }) : 
        (typeof element.sponsorApproverGroup === 'object' && element.sponsorApproverGroup !== null) ? 
        (element.sponsorApproverGroup.adGroupName ? element.sponsorApproverGroup.adGroupName : '') : 
        [],

        vvpm_countries: element.vvpm_countries
          .map((object) => {
            return object.country_name;
          }),
        vam_roles: element.vam_roles
        .map((object) => {
          return object.name;
        })       
        .join(",")
      };
      tempAffliates.push(obj);
    });
    setCsvToDownload(tempAffliates);
  }, [data]);

  const handelSearchSelected = () => {
    setSearchText(searchLocalText);
  };
  const onChangeSearchLocalText = (event) => {
    setSearchLocalText(event.target.value);

    if (event.target.value === "") setSearchText("");
  };
  const handelClearSearchText = () => {
    setSearchLocalText("");
    setSearchText("");
  };
  return (
    <div className="adminDashboardTableSearch">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 230,
            height: 35,
            borderRadius: "5px 0px 0px 5px",
            boxShadow: 0
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "15px" }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            value={searchLocalText}
            onChange={onChangeSearchLocalText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handelSearchSelected();
              }
            }}
          />
          {searchLocalText.length > 0 ? (
            <IconButton
              type="button"
              sx={{ p: "5px" }}
              aria-label="search"
              onClick={handelClearSearchText}
            >
              <CloseIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          ) : null}
        </Paper>
        <Button
          variant="contained"
          className={"reassignAndTerminateSearchUser_searchButton"}
          onClick={handelSearchSelected}
          sx={{ height: "35px", width: "35px" }}
        >
          <SearchOutlinedIcon size="medium" className="searchIcon" />
        </Button>
      </div>

      <div className="filterContainer">
        <div className="reassignAndTerminate_FilterDownload">
          <Button
            variant="contained"
            className="btn btn--filter"
            onClick={handelToggleAddNewModal}
          >
            <AddCircleIcon className="filterIcon" />
            ADD NEW AFFILIATE
          </Button>
          <CSVLink data={csvToDownload} filename="admin affiliate.csv">
            <Button variant="contained" className="btn btn--download">
              <FileDownloadOutlinedIcon className="donwloadIcon" />
              <span>.csv</span>
            </Button>
          </CSVLink>
        </div>
      </div>
    </div>
  );
}

export default FilterInAffiliate;
